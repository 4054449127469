import AuthService from "@/services/AuthService";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import Gard from "@/services/Middleware";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: [Gard.authenticateuser],

    children: [
      {
        path: "/projects",
        name: "projects",
        components:{
          default: () => import("@/views/projects/Projects.vue"),
        },
        meta : {nivelCargo : [100, 200]},
      },
      {
        path: "/project/new",
        name: "Project",
        components:{
          default: () => import("@/views/projects/ProjectEdit.vue"),
        },
        meta : {nivelCargo : [100]},
      },
      {
        path: "/project/edit/:idProject",
        name: "EditProject",
        components:{
          default: () => import("@/views/projects/ProjectEdit.vue"),
        },
        props: true,
        meta : {nivelCargo : [100]},
      },
      {
        path: "/tickets",
        name: "tickets",
        components:{
          default: () => import("@/views/tickets/Tickets.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterDashboard.vue')
        },
        meta : {nivelCargo : [100, 200]},
      },
      {
        path: "/tickets/:id",
        name: "ticketsId",
        components:{
          default: () => import("@/views/tickets/Tickets.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterDashboard.vue')
        },
        meta : {nivelCargo : [100, 200]},
      },
      {
        path: "/listaTickets",
        name: "listaTickets",
        components:{
          default: () => import("@/views/tickets/TicketsList.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterAttendance.vue')
        },
        meta : {nivelCargo : [100, 200]},
      },
      {
        path: "/createFormTickets",
        name: "createFormTickets",
        components:{
          default: () => import("@/views/formCreateTicket/FormCreateTicket.vue"),
        },
        meta : {nivelCargo : [100, 200]},
      },
      {
        path: "/users",
        name: "users",
        components:{
          default: () => import("@/views/usersEdit/Users.vue"),
        },
        meta : {nivelCargo : [100, 200]},
      },
      {
        path: "/projectsList",
        name: "projectsList",
        components:{
          default: () => import("@/views/projects/ProjectsList.vue"),
        },
        meta : {nivelCargo : [100]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/alertSound",
        name: "alertaSound",
        components:{
          default: () => import("@/views/alertSound/AlertSound.vue"),
        },
        meta : {nivelCargo : [100, 200]},
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Login.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
        beforeEnter: [Gard.redirectAuthenticatedUser],
      },
    ],
  },
  {
    // the 401 route, when none of the above matches
    path: "/401",
    name: "401",
    component: () => import("@/views/Error401.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/403",
    name: "403",
    component: () => import("@/views/Error403.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function setBodyStyles() {
  document.body.classList.add("page-loading");
  document.body.classList.remove("modal-open");
  document.body.style.overflow = "auto";
  document.body.style.paddingRight = "0";
}


function resetBodyStyles() {
  document.body.classList.remove("page-loading");
}

function handleUserAuthentication(to) {
  
  const usuario = JSON.parse(AuthService.getUsuario());

  if (to.path !== '/login') {
    try {
      if (!usuario) {
        throw new Error('User not found');
      }
    } catch (error) {
      console.log('[ERRO]', error);
      store.dispatch(Actions.LOGOUT);
    }
  }

  return usuario;
}


function handleUserAuthorization(to, usuario, next) {
    if (to.meta.nivelCargo) {
      if (usuario && Array.isArray(to.meta.nivelCargo) && to.meta.nivelCargo.includes(usuario.nivelCargo)) {
        next(); // Allow access
      } else {
        console.log('Usuário não autorizado');
        next('/403'); // Redirect to unauthorized page
      }
    } else {
      next(); // Allow access if no auth is required
    }
}


router.beforeEach(async (to, from, next) => {
  setBodyStyles();

  const usuario = handleUserAuthentication(to);

  handleUserAuthorization(to, usuario, next);

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach(() => {
  resetBodyStyles();
});
export default router;
